import React from 'react';
import pageStyle from '../../../assets/css/page.module.css';
import style from '../../../assets/css/main.module.css';
import Menu from '../../../components/menu';
import Footer from '../../../components/footer';
import HeroPage from '../../../components/hero.page';
import Hamburger from '../../../components/hamburger';
import { Grid, Row, Col } from 'react-flexbox-grid';

import imgSEO from '../../../assets/img/heroes/remarketing.jpg';
import SimilarCard from '../../../components/similar.card';
import similar1 from '../../../assets/img/heroes/thumbnails/social_branding.jpg';
import similar2 from '../../../assets/img/heroes/thumbnails/social_ads.jpg';
import similar3 from '../../../assets/img/heroes/thumbnails/newsletter.jpg';
import SEO from '../../../components/seo';
import CtaSection from '../../../components/cta.section';
import cta from '../../../assets/img/illustrations/invest.svg';
import contentPic from '../../../assets/img/illustrations/social_dashboard.svg';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Remarketing | Agence digitale | MDS Digital Agency"
      description="Nous utilisons les dernières innovations en matière de référencement pour rendre votre site internet visible sur les moteurs de recherche."
      keywords="référencement"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2 className={pageStyle.header_uppercase}>Remarketing</h2>
      <div className={pageStyle.intro_container} style={{ marginTop: '3em', marginBottom: '6em' }}>
        <h4 className={pageStyle.intro}>Relancer vos leads avec le remarketing Facebook.</h4>
      </div>
      <Grid style={{ marginBottom: '6em', textAlign: 'right' }}>
        <Row>
          <Col md={6} className={pageStyle.flex_v_align}>
            <p>
              Le remarketing Facebook permet de recibler les personnes qui ont déjà visité votre
              site internet et qui connaissent déjà vos produits. Le but ? Relancer vos leads afin
              qu’ils reviennent sur votre site et qu’ils convertissent. Toutes les personnes
              visitant votre site sont des clients potentiels, car ils sont déjà intéressés par vos
              produits ou services.
            </p>
            <p>
              MDS Digital Agency met en place le Pixel Facebook sur votre site internet afin de
              créer les campagnes de remarketing qui seront diffusées dans le fil d’actualité
              Facebook.
            </p>
          </Col>
          <Col style={{ paddingLeft: '50px' }} md={6}>
            <img src={contentPic} alt="" />
          </Col>
        </Row>
      </Grid>

      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="Quel impact aurait le remarketing sur mes ventes ?"
        subtitle="Combien ça coute ?"
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard
            text="Social Branding"
            url="/services/reseaux-sociaux/social-branding/"
            img={similar1}
          />
          <SimilarCard
            text="Social Ads"
            url="/services/reseaux-sociaux/social-ads/"
            img={similar2}
          />
          <SimilarCard text="Newsletter" url="/services/newsletter/" img={similar3} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
